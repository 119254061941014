// load ElementInternals polyfills if not supported by the browser
const supportElementInternals =
  typeof window.ElementInternals === 'function' && typeof HTMLElement.prototype.attachInternals === 'function';

if (!supportElementInternals) {
  console.log('loading ElementInternals polyfill');
  const scriptElement = document.createElement('script');
  scriptElement.async = false;
  scriptElement.src = '/js/element-internals-polyfill.js';
  document.head.appendChild(scriptElement);
}
